<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand href="/">
      <div class="logo"></div>
    </b-navbar-brand>

    <b-navbar-nav>
      <b-nav-item
        v-for="route in routes"
        :key="route.routeName"
        exact-active-class="text-bold"
        :to="{ name: route.routeName }"
      >
        <span class="text-white">{{ route.title }}</span>
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto">
      <b-button variant="outline" class="text-white" @click="logout">Выйти</b-button>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'TopBar',
  data() {
    return {
      routes: [
        { title: 'Посетить', routeName: 'Places' },
        { title: 'Отдых', routeName: 'PlacesTravel' },
        { title: 'Типы мест', routeName: 'PlaceTypes' },
        { title: 'Маршруты', routeName: 'Routes' },
        { title: 'Авторы', routeName: 'Authors' },
      ],
    };
  },
  methods: {
    ...mapActions('auth', ['ActionLogout']),
    async logout() {
      try {
        await this.ActionLogout();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
      this.$router.push({ name: 'Auth' });
    },
  },
});
</script>

<style lang="scss">
$width: 32px;

.navbar-brand {
  color: rgb(255, 155, 155) !important;
  .logo {
    background-image: url(../../assets/logo.png);
    width: $width;
    height: $width;
    background-size: contain;
    border-radius: 8px;
  }
}
</style>
