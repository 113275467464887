<template>
  <div>
    <TopBar />
    <div class="p-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/navigation/TopBar.vue';

export default {
  name: 'AdminLayout',
  components: { TopBar },
};
</script>

<style lang="scss" scoped></style>
